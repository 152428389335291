import { useState, useEffect, FC } from 'react';
import { ModalSendingForm } from '../apps/WriteMessage/Modal/ModalSendingForm';
import { KTIcon } from '../../../_metronic/helpers/components/KTIcon';
import { KTSVG } from '../../../_metronic/helpers';


interface ModalMessageProps {
  is_show: boolean; // is_show 프로퍼티를 정의합니다.
  msg: string | undefined;
  setVisible: (isVisible: boolean) => void; // 함수 props 타입 정의
}

const ModalMessage: FC<ModalMessageProps> = (parent_data) => {

  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);


  return (
    <>
      {parent_data.is_show ? (
        <>


          <div className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className="modal-title">오류</h2>

                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={
                      () => {

                        parent_data.setVisible(false);
                      }
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <KTSVG
                      path="media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                    />
                  </div>
                  {/* end::Close */}
                </div>

                <div className='modal-body scroll-y mx-5 mx-xl-15 my- 7 modal-body'>
                  <div
                    className='d-flex flex-column scroll-y me-n7 pe-7 '
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                  >

                    <div className='fv-row mb-7 '>
                      {/* begin::Label */}
                      <label className='fw-bold fs-3 mb-2'>{parent_data.msg}</label>
                      {/* end::Label */}

                    </div>

                    <span className='fs-3 align-center mx-1'>
                      {/* {status_info} 명 */}
                    </span>
                  </div>
                  {/* end::Scroll */}

                  {/* begin::Actions */}
                  <div className='text-center pt-15 ' >
                    <button type='reset' className='btn btn btn-primary'
                      data-kt-users-modal-action='cancel' onClick={() => parent_data.setVisible(false)}>확인</button>
                  </div>
                </div>


              </div>
            </div>
          </div><div className='modal-backdrop fade show'></div></>
      ) : (
        <div></div>
      )}
    </>
  );
};

export { ModalMessage };