import { useState, useEffect, FC } from 'react';
import { ModalSendingHeader } from './ModalSendingHeader';
import { ModalSendingForm } from './ModalSendingForm';
import { useWriteMessage } from '../WriteMessageProvider';



const ModalSending: FC = () => {
  const { is_show, setVisible } = useWriteMessage()

  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <>
      {is_show ? (
        <><div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'>
          <div className='modal-dialog modal-dialog-centered mw-650px'>
            <div className='modal-content'>
              {/* <ModalSendingHeader /> */}
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <ModalSendingForm />
              </div>
            </div>
          </div>
        </div><div className='modal-backdrop fade show'></div></>
      ) : (
        <div></div>
      )};
    </>
  );
};

export { ModalSending };