import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { GetGroup } from '../../friend-add/friend-list/core/_requests';
import { GroupList } from '../../friend-add/friend-list/core/_models';
import { useGroupRequest } from '../GroupContextProvider';
import { GroupuseQueryResponse } from '../GroupQueryResponseProvider';
import { Button } from 'react-bootstrap';

const MyTable: React.FC<{ data: GroupList[] }> = ({ data }) => {
    const [selectedButtonId, setSelectedButtonId] = useState<string | undefined>('-1');
    
    const value = useGroupRequest();

    const handleButtonClick = (id: string | undefined) => {
        setSelectedButtonId(id) // 변수 변경
        value?.updateValue(id) // 선택 값 위로 올리기
      }; 
    

      useEffect(() => {
        setSelectedButtonId(value?.value);
      }, []);
    


return (
    <>
    <ul className='p-0'>
        <li className='p-1 m-1 mt-0' style={{ listStyle: 'none' }}>
            {data.map((user, index) => (
                <button
                    style={{ padding: '0px', margin: '0px', display: 'block', width: '100%', textAlign: 'left' }}
                    key={user.id}
                    id={user.id}
                    type='button'
                    className={`btn p-2 ${user.id === selectedButtonId ? 'btn-primary' : ''} `}
                    onClick={() => handleButtonClick(user.id)}
                >
                    {user.name} ({user.cnt})
                </button>

            ))}
        </li>
    </ul></>

)
}



const GroupTable = () => {
    const [data, setData] = useState<GroupList[]>([]);
    const [loading, setLoading] = useState(true);

    const value = useGroupRequest();

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await GetGroup()
                if (response.map.length > 0) {
                    setData(response);
                    setLoading(false);
                }
                else {
                    setData([]);
                    setLoading(false);
                }
            } catch (error) {
                setData([]);
                setLoading(false);

                //console.error('Error fetching data:', error);
            }
        };


        setLoading(true);
        fetchData();
    }, [value?.cnt]);



    if (loading) {
        return <div>로딩중(전체 dialog로 바꾸자)</div>;
    }

    return <MyTable data={data} />;
};

export { GroupTable }