/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
  phonenumber: Yup.string()
  .min(9, '최소 9글자 이상입니다.')
  .max(11, '최대 11글자 이하입니다.')
  .required('휴대폰 번호를 입력 해주세요'),
  password: Yup.string()
  .min(3, '최소 3글자 이상 입력해주세요')
  .max(50, '최대 50글자 이하로 입력해주세요')
  .required('비밀번호를 입력 해주세요'),
})

// const initialValues = {
//   phonenumber: '01012341234',
//   password: '123456',
// }

const initialValues = {
  phonenumber: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.phonenumber, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('휴대폰 번호 또는 비밀번호가 일치하지 않습니다.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >

      <div className='text-center mb-11'>
        <div className='fw-bold fs-4' style={{color: '#27ae61'}}>피치톡으로 마케팅 한번에 해결!</div>
        <img src='/media/peachtalk/logo.png' className='w-50 p-10' alt='피치톡로고' />
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-white p-8 rounded'>
          <div className='text-info'>
            -를 제외한 휴대폰번호를 입력해주세요.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>휴대폰번호</label>
        <label className='form-label fs-6 fw-bolder' style={{color:'#f97f50'}}> -를 제외한 번호만 입력해 주세요.</label>        
        <input
          placeholder='휴대폰 번호를 입력해 주세요'
          {...formik.getFieldProps('phonenumber')}
          className={clsx(
            'form-control bg-white',
            {'is-invalid': formik.touched.phonenumber && formik.errors.phonenumber},
            {
              'is-valid': formik.touched.phonenumber && !formik.errors.phonenumber,
            }
          )}
          type='tel'
          name='phonenumber'
          autoComplete='off'
        />
        {formik.touched.phonenumber && formik.errors.phonenumber && (
          <div className='fv-plugins-message-container '>
            <span role='alert'>{formik.errors.phonenumber}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>비밀번호</label>
        <input
          type='password'
          placeholder='비밀번호를 입력해 주세요'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-white',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          비밀번호를 잃어버렸나요 ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>로그인</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              잠시만 기다려주세요...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        회원이 아니신가요?{' '}
        <Link to='/auth/registration' className='link-primary'>
          가입하기
        </Link>
      </div>
    </form>
  )
}
