import { FC, useEffect, useState } from 'react'
import { useWriteMessage } from './WriteMessageProvider';
import { useLocation } from 'react-router-dom';

interface RequestData
{
  selected : Array<String>
}

const SendList: FC = () => {
  const location = useLocation();
  const receivedData = location.state as RequestData ;


  const {selected, setSelectedItems} = useWriteMessage();  

  useEffect(() => {
    setSelectedItems(receivedData.selected);
  }, []);

  

// formattedValue를 state로 선언
const [formattedValue, setFormattedValue] = useState<string>("");

  // selected 값이 변경될 때마다 어떤 작업을 수행
  useEffect(() => {
    // selected 값이 변경될 때마다 실행하고자 하는 작업을 여기에 추가
    console.log('selected 값이 변경되었습니다.', selected);

    if (Array.isArray(selected)) {
      // 배열의 값들을 콤마로 연결하여 formattedValue 업데이트
      setFormattedValue(selected.join(', '));
    } else {
      // 배열이 아닌 경우 빈 문자열로 설정
      setFormattedValue("");
    }

    // 예시: selected 값이 변경될 때 다른 작업을 수행하려면 여기에 추가
  }, [selected]);


  return(
    
  <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
    <div className='card-header cursor-pointer'>
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>수신자 ({selected.length}명)</h3>
      </div>
    </div>

    <div className='card-header cursor-pointer'>
      <div className='card-title m-0'>
      <label className="col-form-label fw-bold fs-6" >{formattedValue}</label>
      </div>
    </div>    
  </div>
  );
} 
  

export { SendList }
