import { useState, useEffect } from 'react';
import { KTCard, KTIcon } from '../../../../../_metronic/helpers';
import { GroupEditPopup } from '../../../../pages/GroupManage/GroupEditPopup';
import { GroupuseManageQueryResponse } from '../../../../pages/GroupManage/GroupQueryResponseProvider_GroupManage';
import { GroupListViewProvider_Manage } from '../../../../pages/GroupManage/GroupListViewProvider_Manage';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { register_group } from '../../friend-add/friend-list/core/_requests';
import { PasswordMeterComponent } from '../../../../../_metronic/assets/ts/components';
import clsx from 'clsx';
interface GroupEditTableProps {
  isOpen: boolean;
  onClose: () => void;
}

const GroupEditTable: React.FC<GroupEditTableProps> = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  const cancel = () => {
    onClose();
  }


  const { refetch } = GroupuseManageQueryResponse()

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])


  const [loading, setLoading] = useState(false)

  const initialValues = {
    group_name: '',
  }

  const registrationSchema = Yup.object().shape({

    group_name: Yup.string()
      .min(2, '최소 2글자 이상 입력해주세요')
      .max(30, '최대 30글자 이하로 입력해주세요.')
      .required('추가할 그룹명을 입력해주세요'),
  })


  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,

    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      try {
        const { data: auth } = await register_group(values.group_name)

        refetch(); // 갱신 넣어야 하는데
        
        resetForm()  // Reset the form to its initial values

        setStatus('추가 완료')
        setSubmitting(false)
        setLoading(false)

      } catch (error) {
        console.error(error)
        setStatus('추가 오류')
        setSubmitting(false)
        setLoading(false)


      }
    },
  })


  return (
    <>
      {isOpen && (
        <>
          <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
            {/* begin::Modal dialog */}

            <div className='modal-dialog modal-dialog-centered mw-950px'>
              {/* begin::Modal content */}
              <div className='modal-content'>
                {/* begin::Modal body */}


                <KTCard>
                  <div className='card-header border-0 pt-0 w-100 '>
                    <div className='card-toolbar w-100 mt-10'>
                      <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_signup_form'
                        onSubmit={formik.handleSubmit}
                      >

                        <div className='row gy-5 gx-xl-5 '>

                          <span className='col-xxl-3 form-label fw-bolder text-dark fs-3 p-2 text-center'>그룹 추가</span>


                          <div className='col-xxl-6'>
                            <input
                              type='text'
                              autoComplete='off'
                              {...formik.getFieldProps('group_name')}
                              onBlur={formik.handleBlur}
                              value={formik.values.group_name}
                              data-kt-user-table-filter='search'
                              className={clsx(
                                'form-control bg-white col-xxl-2',
                                {
                                  'is-invalid': formik.touched.group_name && formik.errors.group_name,
                                },
                                {
                                  'is-valid': formik.touched.group_name && !formik.errors.group_name,
                                }
                              )}
                              placeholder='그룹명'
                            />
                          </div>

                          <button type='submit' id='kt_sign_up_submit' className='btn btn-danger ms-4 col-xxl-2'>
                            {!loading && <span className='indicator-label'>그룹 추가</span>}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                그룹 추가 {' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>

                        </div>


                        {formik.touched.group_name && formik.errors.group_name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-center'>
                              <span role='alert'>{formik.errors.group_name}</span>
                            </div>
                          </div>
                        )}
                      </form>

                    </div>
                  </div>

                      <GroupEditPopup />



                </KTCard>

                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <button
                    type='reset'
                    onClick={() => cancel()}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    닫기
                  </button>


                </div>
                {/* end::Modal body */}
              </div>
              {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}


          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show' ></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  );
};

export { GroupEditTable };