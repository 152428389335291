import { FC, useState, useEffect } from 'react'
import { initialUser, StatusInfo, User } from '../core/_models'
import { useListView } from '../core/ListViewProvider'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { KTIcon } from '../../../../../../_metronic/helpers/components/KTIcon'

type Props = {
  isUserLoading: boolean
  user: User
  status_info: number | undefined
  fnc_stop: () => void // 추가된 prop의 타입 정의
}


const UserEditModalForm: FC<Props> = ({ isUserLoading, status_info, fnc_stop }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
    fnc_stop();
    
    //IntervalFindFriend_End();
  }


  return (
    <>
      <form id='kt_modal_add_user_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          <div className='fv-row mb-7 text-center'>
            <div
              className='btn btn-icon btn-sm btn-active-icon-primary'
              data-kt-users-modal-action='close'
              onClick={
                () => cancel()
              }
              style={{ cursor: 'pointer', position: 'absolute', right: '0px' }}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>


            <label className='fw-bold fs-1 mb-2 ' style={{ color: '#f97f50' }}>친구 등록</label>
            <br></br><br></br>

            <label className='fw-bold fs-2 mb-2'>카카오톡 친구 명단을 불러오고 있습니다.</label>

            <br></br>
            <span className='fw-bold align-center mx-1' style={{ fontSize: '50px' }}>
              {status_info} 명
            </span>
            <br></br>
            <label className='fw-bold fs-5 mb-2 ' style={{ color: '#f97f50' }}>명단을 가져오고 있습니다.<span className="spinner-border spinner-border-sm align-middle ms-2"></span>     </label>
            <br></br>
            <br></br>
            {/* begin::Label */}
            <label className='fw-bold fs-5 mb-2'>마우스, 키보드를 건들이지 마세요.</label>
            <br></br>
            <label className='fw-bold fs-6 mb-2'>등록 과정에서 카카오톡 채팅창이 열리고 닫힙니다.</label>
            {/* end::Label */}



          </div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-primary me-3 p-20 pt-3 pb-3 fw-bold fs-5'
            data-kt-users-modal-action='cancel'
            disabled={isUserLoading}
          >
            등록 중지
          </button>

        </div>
        {/* end::Actions */}
      </form>
      {(isUserLoading) && <UsersListLoading />}
    </>
  )
}



export { UserEditModalForm }
