import { KTCard, KTIcon } from "../../../../_metronic/helpers";
import { UsersListSearchComponent } from "../friend-add/friend-list/components/header/UsersListSearchComponent";
import { UsersListGrouping } from "../friend-add/friend-list/components/header/UsersListGrouping";
import { GroupTable } from "./group-list/GroupTable";
import { useState } from "react";
import { GroupQueryResponseProvider } from "./GroupQueryResponseProvider";
import { GroupFriendTable } from "./GroupFriendTable";
import { GroupQueryRequestProvider } from "./GroupQueryRequestProvider";
import { ListViewProvider } from "../friend-add/friend-list/core/ListViewProvider";
import { QueryRequestProvider } from "../friend-add/friend-list/core/QueryRequestProvider";
import { GroupListViewProvider } from "./GroupListViewProvider";
import { GroupUsersListGrouping } from "../friend-add/friend-list/components/header/GroupUsersListGrouping";
import { GroupEditTable } from "./group-list/GroupEditTable";
import { GroupQueryResponseProvider_GroupManage } from "../../../pages/GroupManage/GroupQueryResponseProvider_GroupManage";
import { GroupListViewProvider_Manage } from "../../../pages/GroupManage/GroupListViewProvider_Manage";


const FriendGroupList = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // 모달 열기
    const openModal = () => {
        setIsModalOpen(true);
    };

    // 모달 닫기
    const closeModal = () => {
        setIsModalOpen(false);
    };



    return (
        <>
            <div className='row gy-5 gx-xl-5'>
                <div className='row gy-5 gx-xl-2' style={{ border: '2px solid #efefef', borderRadius: '20px' }}>

                    <div className='col-xxl-3 ' style={{ borderRight: '1px solid #efefef' }} >

                        <KTCard>

                            <button
                                type='button'
                                onClick={openModal}
                                className='btn btn-primary fw-bold float-end m-2'>
                                그룹편집

                                <i className="bi bi-gear ms-1"></i>
                            </button>

                            <div className='border-0 p-3 pt-12 ' style={{ textAlign: 'left' }}>
                                <span className='card-label fw-bold fs-3 mb-1'>그룹</span>
                            </div>
                            

                            <GroupQueryResponseProvider_GroupManage>
                                <GroupListViewProvider_Manage>
                                    <GroupEditTable isOpen={isModalOpen} onClose={closeModal} />
                                </GroupListViewProvider_Manage>
                            </GroupQueryResponseProvider_GroupManage>                            

                            <GroupTable />

                        </KTCard>
                    </div>



                    <div className='col-xxl-9'>

                        <KTCard>
                            <div className='card-header border-0 pt-0'>
                                <UsersListSearchComponent />
                                <div className='card-toolbar'>
                                    <GroupUsersListGrouping />
                                </div>
                            </div>
                            <GroupFriendTable />
                        </KTCard>
                    </div>

                </div>

            </div>

        </>
    )
}


const FriendGoupListWrapper = () => (

    <QueryRequestProvider>
        <GroupQueryResponseProvider>
            <GroupListViewProvider>
                <FriendGroupList />
            </GroupListViewProvider>
        </GroupQueryResponseProvider>
    </QueryRequestProvider>

)




export { FriendGoupListWrapper }

