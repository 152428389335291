import { useState, useEffect } from 'react';
import { UserEditModalHeader } from './UserEditModalHeader';
import { UserEditModalForm } from './UserEditModalForm';
import { SendFriendAll_Start, SendFriendAll_Stop, Send_Friends, Send_Status } from '../core/_requests';
import { StatusInfo } from '../core/_models';
import { useListView } from '../core/ListViewProvider';
import { AuthModel, getAuth } from '../../../../auth';
import { useQueryResponse } from '../core/QueryResponseProvider';
import { sleep } from 'react-query/types/core/utils';

let interval: NodeJS.Timer;

const UserEditModal = () => {
  const [statusInfo, setStatusInfo] = useState<number | undefined>(0);
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const intervalFindFriendStart = () => {
    SendFriendAll_Start();

    // 3초 동안 interval을 시작하지 않습니다.
    setTimeout(() => {
      // interval을 시작합니다.
      interval = setInterval(() => {
        Send_Status().then((result: StatusInfo) => {

          // 명단 등록 수 UI 업데이트
          setStatusInfo(result.current_sync_cnt);


          // 프로그램이 레디 상태 이면(전체 명단 불러오기 종료일 경우)
          if (result.status != "GetKaoFriends") {
            // 전체 명단 불러오기 강제 종료 처리
            SendFriendAll_Stop();
            clearInterval(interval);
            setItemIdForUpdate(undefined);
          }
          //console.log('Count:', result.current_sync_cnt);
        });
      }, 1000); // 1초 간격으로 호출
    }, 2000); // 지연시간 후에

  };



  const IntervalFindFriend_End = async () => {
    clearInterval(interval);

   await SendFriendAll_Stop()
      .then(async (result) => {

        const lsValue: AuthModel | undefined = getAuth();
        const lsValueAsString: string | undefined = lsValue ? JSON.stringify(lsValue) : undefined;

        await Send_Friends(lsValueAsString);

        refetch();
        //setItemIdForUpdate(undefined);
      });

      
  }





  useEffect(() => {
    document.body.classList.add('modal-open');

    intervalFindFriendStart();

    return () => {
      document.body.classList.remove('modal-open');
      clearInterval(interval);
    };
  }, []);

  return (
    <>

      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* <UserEditModalHeader /> */}
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <UserEditModalForm isUserLoading={false} user={{ id: undefined }} status_info={statusInfo} fnc_stop={IntervalFindFriend_End} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { UserEditModal };