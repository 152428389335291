import { useState, useEffect, FC } from 'react';
import { ModalSendingForm } from '../apps/WriteMessage/Modal/ModalSendingForm';


interface LoadingProps {
  is_show: boolean; // is_show 프로퍼티를 정의합니다.
  setVisible: (isVisible: boolean) => void; // 함수 props 타입 정의
}

const ModalLoading: FC<LoadingProps> = (  parent_data  ) => {
  
  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);


  return (
    <>
      {parent_data.is_show ? (
        <><div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'>
          <div className='modal-dialog modal-dialog-centered mw-450px mh-350px'>
            <div className='modal-content'>
           
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* end::Input group */}
          <button type="button" className="btn btn-primary" data-kt-indicator="on">
            <span className="indicator-label">
              Submit
            </span>
            <span className='indicator-progress' style={{display: 'block'}}>
              잠시만 기다려주세요...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>            
          </button>

          <span className='fs-3 align-center mx-1'>
            {/* {status_info} 명 */}
          </span>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
      
              </div>
            </div>
          </div>
        </div><div className='modal-backdrop fade show'></div></>
      ) : (
        <div></div>
      )}
    </>
  );
};

export { ModalLoading };