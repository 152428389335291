import {FC} from 'react'

type Step = {
  id: string;
  name: string;
};

type Props = {
  two_steps?: string;
};


const UserTwoStepsCell: FC<Props> = ({ two_steps }) => {
  let steps: Step[] = [];

  try {
    if (two_steps) {
     // steps = JSON.parse(two_steps.replace(/id/g, '"id"').replace(/name/g, '"name"')) as Step[];
    }
  } catch (error) {
    console.error('Error parsing two_steps:', error);
  }

  return (
    <>
      {steps.map((step) => (
        <div className='badge badge-light-success fw-bolder m-2 fs-5' key={step.id}>
          {step.name}
        </div>
      ))}
    </>
  );
};

export {UserTwoStepsCell}
