/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      
    >
      {/* begin::Content */}
      <div className='container p-19'>
        {/* begin::Logo */}
       
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='bg-body rounded shadow-sm mx-auto' style={ {width:'100%'}}>
          <div className='row'>
            <div className='col-xl-6 p-lg-10 p-lg-20 ' style={{backgroundColor:'#fff9f6'}}>
            <Outlet  />
          </div>
          <div className='col-xl-6 p-0 shadow-sm mx-auto' style={{
            borderTopRightRadius:'0.475rem',
            borderBottomRightRadius:'0.475rem',            
            background: '#f5a282',
            // backgroundImage:`url('/media/peachtalk/banner1.jpg')`, backgroundSize: 'cover', backgroundRepeat : 'no-repeat'
          }
            }>
            <img src='/media/peachtalk/banner1.jpg' className='w-100 '></img>
          </div>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
     
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
