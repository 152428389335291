import { KTCard } from "../../../../_metronic/helpers";
import { UsersListGrouping } from "../friend-add/friend-list/components/header/UsersListGrouping";
import { UsersListHeader } from "../friend-add/friend-list/components/header/UsersListHeader";
import { UsersListSearchComponent } from "../friend-add/friend-list/components/header/UsersListSearchComponent";
import { ListViewProvider, useListView } from "../friend-add/friend-list/core/ListViewProvider";
import { QueryRequestProvider } from "../friend-add/friend-list/core/QueryRequestProvider";
import { QueryResponseProvider } from "../friend-add/friend-list/core/QueryResponseProvider";
import { Add_Group_Friend } from "../friend-add/friend-list/core/_requests";
import { FriendTable } from "../friend-add/friend-list/table/FriendTable";
import { FriendTableSimple } from "../friend-add/friend-list/table/FriendTableSimple";
import { UserEditModal } from "../friend-add/friend-list/user-edit-modal/UserEditModal";
import { useGroupRequest } from "./GroupContextProvider";



const FriendaAddList = () => {
    const value = useGroupRequest();

    const { itemIdForUpdate, selected } = useListView()
    
    const {clearSelected} = useListView()
    

    const AddFriendData = async () => {
        try {
          await Add_Group_Friend(selected, value?.value); // Add_Group_Friend 함수가 Promise를 반환하도록 가정
                      
          value?.updateCnt(); // 우측 그룹이 새로고침됨
          clearSelected(); // 좌측 친구목록이 새로고침됨

          // 응답이 정상적으로 처리된 후에 실행되는 코드
          // 이 부분에서 다음 구문으로 이동하거나 필요한 동작을 수행할 수 있습니다.
        } catch (error) {
          // 에러 처리
        }
      };

    return (
        <>
            <div className='row gy-5 gx-xl-5'>
                <div className='col-xxl-9 p-1'  style={{border:'2px solid #efefef', borderRadius:'20px'}}>
                    <KTCard>
                        <div className='card-header border-0 pt-6'>
                            <UsersListSearchComponent />
                            <div className='card-toolbar'>
                                <UsersListGrouping />
                            </div>
                        </div>
                        <FriendTableSimple />
                    </KTCard>
                </div>

                <div className='col-xxl-3 d-flex align-items-center'>
                    <button
                        type='button'
                        onClick={AddFriendData}
                        className='btn btn-primary fw-bold w-100'>
                        추가 {selected.length}명
                        <i className="bi bi-arrow-right ms-1"></i>
                    </button>
                </div>

            </div>

            {itemIdForUpdate !== undefined && <UserEditModal />}
        </>
    )
}


const FriendAddListWrapper = () => (

    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <FriendaAddList />
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>

)




export { FriendAddListWrapper }
