import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../_metronic/layout/core'
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../_metronic/partials/widgets'
import { UsersListWrapper } from '../modules/apps/friend-add/friend-list/FriendList'
import { FriendAddListWrapper } from '../modules/apps/friend-group-manage/FriendaAddList'
import { GroupContextProvider } from '../modules/apps/friend-group-manage/GroupContextProvider'
import { FriendGoupListWrapper } from '../modules/apps/friend-group-manage/FriendGroupList'
import { Send_GetProgramStatus } from '../modules/apps/friend-add/friend-list/core/_requests'


const GroupManagePage: FC = () => (
  <>
    <GroupContextProvider>
    <div className='row gy-5 gx-xl-5'>
      <div className='col-xxl-6'>
        <FriendAddListWrapper />
      </div>


      <div className='col-xxl-6'>
        <FriendGoupListWrapper />
      </div>
    </div>
    </GroupContextProvider>
  </>
)


const GroupManage: FC = () => {
  const intl = useIntl()

    
  useEffect(() => {
    Send_GetProgramStatus();
  }, []);
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'전송그룹 관리'}</PageTitle>
      <GroupManagePage />
    </>
  )
}

export { GroupManage }
