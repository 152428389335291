/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/home'
        icon='element-1'
        title={'피치톡'}
        fontIcon='bi-app-indicator'
      />
      {/* { <SidebarMenuItem
        to='/crafted/account/overview'
        icon='bi bi-person-lines-fill'
        title={'마이페이지'}
        fontIcon='bi-app-indicator'
      /> } */}

{ <SidebarMenuItem
        to='/myproduct'
        icon='bi bi-person-lines-fill'
        title={'내 이용권'}
        fontIcon='bi-app-indicator'
      /> }

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-3 ls-1'>카카오톡</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/friend-add/users'
        // to='/apps/user-management/users'        
        icon='bi bi-person-plus-fill'
        title={'친구 등록'}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/groupmanage'
        icon='bi bi-chat-text'
        title={'전송그룹 관리'}
        fontIcon='bi-app-indicator'
      />      
      <SidebarMenuItem
        to='/sendmessage1'
        icon='bi bi-chat-text'
        title={'메시지 전송'}
        fontIcon='bi-app-indicator'
      />



    </>
  )
}

export { SidebarMenuMain }
