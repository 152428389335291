/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../_metronic/helpers'


import {getUsersGroupList}  from '../../modules/apps/friend-add/friend-list/core/_requests' // 데이터 가져오는 쿼리문


import { GroupList } from './GroupEditPopup'
import { GroupuseQueryRequest } from './GroupQueryRequestProvider_Manage'
import { useGroupManageRequest } from './GroupContextProvider_Manage'


const GroupQueryResponseContext_GroupManage = createResponseContext<GroupList>(initialQueryResponse)
const GroupQueryResponseProvider_GroupManage: FC<WithChildren> = ({children}) => {
  const {state} = GroupuseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [idx, setIdx] = useState<string | undefined >('-1');

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])


  const value = useGroupManageRequest();
  useEffect(() => {
    setIdx(value?.value);
  }, [value?.value])  

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    'group_list_manage',
    () => {
      return getUsersGroupList(value?.value)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
 

  useEffect(() => {
    refetch()
  }, [value?.value,value?.cnt])



  return (
    <GroupQueryResponseContext_GroupManage.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </GroupQueryResponseContext_GroupManage.Provider>
  )
}

const GroupuseManageQueryResponse = () => useContext(GroupQueryResponseContext_GroupManage)

const GroupuseQueryResponseData = () => {
  const {response} = GroupuseManageQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const GroupuseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = GroupuseManageQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const GroupuseQueryResponseLoading = (): boolean => {
  const {isLoading} = GroupuseManageQueryResponse()
  return isLoading
}

export {
  GroupQueryResponseProvider_GroupManage,
  GroupuseManageQueryResponse,
  GroupuseQueryResponseData,
  GroupuseQueryResponsePagination,
  GroupuseQueryResponseLoading,
}
