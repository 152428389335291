import clsx from 'clsx'
import React, {FC, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../_metronic/helpers'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup, PageTitle} from '../../_metronic/layout/core'

const MyPassPage: React.FC = () => {
  const [tab, setTab] = useState('Sidebar')
  const [config, setConfig] = useState<ILayout>(getLayoutFromLocalStorage())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)

  const updateConfig = () => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
      window.location.reload()
    } catch (error) {
      setConfig(getLayoutFromLocalStorage())
      setConfigLoading(false)
    }
  }

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setConfig(getLayoutFromLocalStorage())
      setResetLoading(false)
    }, 1000)
  }

  return (
    <>

      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs
            fw-bold
            border-transparent
            flex-nowrap
          '
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Sidebar'})}
                onClick={() => setTab('Sidebar')}
                role='tab'
              >
                이용권 내역
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Header'})}
                onClick={() => setTab('Header')}
                role='tab'
              >
                결제내역
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === 'Toolbar'})}
                onClick={() => setTab('Toolbar')}
                role='tab'
              >
                정지결제 정보
              </a>
            </li>
          </ul>
        </div>

        <form className='form'>
          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', {active: tab === 'Sidebar'})}>
                <div className='form-group d-flex flex-stack'>
                  <div className='d-flex flex-column'>
                    <h4 className='fw-bold text-dark'>보유한 이용권이 없습니다.</h4>
                    <div className='fs-7 fw-semibold text-muted'>정기결제 등록 후 사용 가능 합니다.</div>
                  </div>
                </div>
                {/* <div className='separator separator-dashed my-6'></div> */}
          
              </div>

              <div className={clsx('tab-pane', {active: tab === 'Header'})}>
                <div className='form-group d-flex flex-stack'>
                  <div className='d-flex flex-column'>
                    <h4 className='fw-bold text-dark'>없음</h4>
                    <div className='fs-7 fw-semibold text-muted'>결제 내역 없음</div>
                  </div>
                </div>
                {/* <div className='separator separator-dashed my-6'></div> */}
                
              </div>

              <div className={clsx('tab-pane', {active: tab === 'Toolbar'})}>
                <div className='form-group d-flex flex-stack'>
                  <div className='d-flex flex-column'>
                    <h4 className='fw-bold text-dark'>정기결제</h4>
                    <div className='fs-7 fw-semibold text-muted'>2024.11.5 결제 예정 30,000원 (카드)</div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='d-flex justify-content-end'>
                    <button
          type='button'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'>
           <span className='indicator-label'>해제</span>
        </button>
                    </div>
                  </div>
                </div>
                {/* <div className='separator separator-dashed my-6'></div> */}
             
              </div>
            </div>

          </div>
        </form>
      </div>
    </>
  )
}


const MyPass: FC = () => {
  return (
    <>
    <PageTitle breadcrumbs={[]}>{'내 이용권'}</PageTitle>
    <MyPassPage />
    </>
  )
}

export { MyPass }
