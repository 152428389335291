import { FC, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { relative } from 'path';
import { useWriteMessage } from './WriteMessageProvider';
import { Link } from 'react-router-dom';
import { auto, main } from '@popperjs/core';
import { Send_SendMessage, Send_SendMessageStop, Send_Status } from '../friend-add/friend-list/core/_requests';
import { StatusInfo } from '../friend-add/friend-list/core/_models';

const imageStyle: React.CSSProperties = {
  backgroundImage: `url(${toAbsoluteUrl('/media/message/pre_phone.jpg')})`,
  backgroundSize: 'cover', // 이미지 크기를 컨테이너에 맞춤
  backgroundRepeat: 'no-repeat',
  width: 334,
  height: 700,
  position: 'relative',
};

const textareaStyle: React.CSSProperties = {
  backgroundColor: '#BDD7EE',
  position: 'relative',
  width: 313,
  height: 525,
  top: 68,
  border: 'NONE',
  overflow: 'auto',
  resize: 'none',
  margin: '0 auto',
};
const spanStyle: React.CSSProperties = {
  whiteSpace: 'pre-line', // 줄 바꿈 활성화
};

const PreviewPhone: FC = () => {
  const { text, setText } = useWriteMessage();
  const { selected } = useWriteMessage();
  const { imageFiles, setImageFiles } = useWriteMessage() // 이미지 입력  
  const { is_show, setVisible } = useWriteMessage()
  const { sent_current, setSentCurrent } = useWriteMessage();


  function replaceEnterWithLineBreak(text: String) {
    //return text.replace(/\n/g, '<br/>') ;
    return '"123"</br>"123"';
  }

  const SendSelected = async () => {
    setSentCurrent(0);

    setVisible(true)

    if (true) {
      const attached_files = [];
      const n_file = imageFiles.length; // 생성할 메시지 개수
      for (let i = 0; i < n_file; i++) {
        const fileData = await readFile(imageFiles[i]);
        const fileMessage = {
          file_name: imageFiles[i].name,
          file_data: fileData,
        };
        attached_files.push(fileMessage);
      }

      const send_list = selected.join(', ');
      Send_SendMessage(send_list, text, attached_files);
    }
    
    ///CheckStatusLoop();
  }

  
let interval: NodeJS.Timer;
  const CheckStatusLoop = () => {

    // 3초 동안 interval을 시작하지 않습니다.
    setTimeout(() => {
      // interval을 시작합니다.
      interval = setInterval(() => {
        Send_Status().then((result: StatusInfo) => {

          // 전송 수 UI 업데이트
          //setStatusInfo(result.current_sync_cnt);
          setSentCurrent(result.current_sent_order as number)


          // 프로그램이 레디 상태 이면(전체 명단 불러오기 종료일 경우)
          if (result.status != "SendMessage") {
            // 전체 명단 불러오기 강제 종료 처리
            Send_SendMessageStop();
            clearInterval(interval);
            //setVisible(false);
          }

          //console.log('Count:', result.current_sync_cnt);
        });
      }, 1000); // 1초 간격으로 호출
    }, 2000); // 지연시간 후에

  };


  // 비동기 처리를 위한 Promise를 사용
  const readFile = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const SendSelf = async () => {
    const attached_files = [];
    const n_file = imageFiles.length; // 생성할 메시지 개수
    for (let i = 0; i < n_file; i++) {
      const fileData = await readFile(imageFiles[i]);
      const fileMessage = {
        file_name: imageFiles[i].name,
        file_data: fileData,
      };
      attached_files.push(fileMessage);
    }

    Send_SendMessage('', text, attached_files);
  }


  return (
    <div className='card mb- mb-xl-0 text-center' id='kt_profile_details_view'>

      <div id='kt_account_profile_details' className=' card-header  collapse show'>
        <div className='card-body border-top p-3'>
          <div className='row mb-0 p-0' style={{justifyContent:'center'}}>
            <button type='button' className='btn btn-dark p-4 col-lg-6' onClick={SendSelf} style={{display:'none'}}>
              <i className="bi bi-send fs-4 me-2"></i>
              테스트 발송 (나에게)
            </button>
            <button type='button' className='btn btn-primary p-4 col-lg-6' onClick={SendSelected}>
              <i className="bi bi-send fs-4 me-2"></i>
              메시지 발송 ({selected.length}명)
            </button>
          </div>
        </div>
      </div>

      <div className='card-header cursor-pointer justify-content-center'>
        <div className='card-title mb-10'>
          <div style={imageStyle}>
            <div className='ttttt' style={textareaStyle}>

              {imageFiles.map((image, index) => (
                <div key={index}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Uploaded Image ${index + 1}`}
                    style={{ maxWidth: '100%', maxHeight: '300px' }}
                  />
                </div>
              ))}

              <span style={spanStyle}>{text as string}</span>

            </div>
            {/* 내용 */}



          </div>
        </div>
      </div>
      <style>
        {`
          .ttttt::-webkit-scrollbar {
            width: 10px;
          }          
          .ttttt::-webkit-scrollbar-thumb {
            height: 30%;
            background: #0095e8 !important;
            border-radius: 10px;
          }          
          .ttttt::-webkit-scrollbar-track {
            background: rgba(33, 122, 244, 0.1);
          }
        `}
      </style>



    </div>
  );
}

export { PreviewPhone }
