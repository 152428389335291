import {useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'

import { auto } from '@popperjs/core'
import { GroupuseQueryResponseData, GroupuseQueryResponseLoading } from './GroupQueryResponseProvider'
import { usersColumns } from '../friend-add/friend-list/table/columns/_columns_simple_group'
import { User } from '../friend-add/friend-list/core/_models'
import { CustomRow } from '../friend-add/friend-list/table/columns/CustomRow'
import { UsersListLoading } from '../friend-add/friend-list/components/loading/UsersListLoading'
import { KTCardBody } from '../../../../_metronic/helpers'
import { CustomHeaderColumn } from './GroupCustomHeaderColumn'

const GroupFriendTable_Send = () => {
  const users = GroupuseQueryResponseData()  
  const isLoading = GroupuseQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })



  

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'  style={{height:'70vh', overflow:auto}}>

        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    등록된 친구가 없습니다.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>


      <style>
        {`
          .table-responsive::-webkit-scrollbar {
            width: 10px;
          }          
          .table-responsive::-webkit-scrollbar-thumb {
            height: 30%;
            background: #0095e8 !important;
            border-radius: 10px;
          }          
          .table-responsive::-webkit-scrollbar-track {
            background: rgba(33, 122, 244, 0.1);
          }
        `}
      </style>


      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {GroupFriendTable_Send}
