import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../_metronic/layout/core'
import { SendList } from '../modules/apps/WriteMessage/SendList'
import { WriteMessage } from '../modules/apps/WriteMessage/WriteMessage'
import { PreviewPhone } from '../modules/apps/WriteMessage/PreviewPhone'
import { WriteMessageProvider } from '../modules/apps/WriteMessage/WriteMessageProvider'
import { ModalSending } from '../modules/apps/WriteMessage/Modal/ModalSending'

const SendMessagePage2: FC = () => {

  return (
    <WriteMessageProvider>
      <div className='row gy-5 gx-xl-5'>
        <div className='col-xxl-4'>
          <SendList />
        </div>

        <div className='col-xxl-4'>
          <WriteMessage />
        </div>

        <div className='col-xxl-4'>
          <PreviewPhone />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <ModalSending />
      </div>
    </WriteMessageProvider>
  )

}


const SendMessage2: FC = () => {

  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{'메시지 작성'}</PageTitle>
      <SendMessagePage2 />
      {/* <pre>{JSON.stringify(receivedData, null, 2)}</pre> */}
    </>
  )
}

export { SendMessage2 }
