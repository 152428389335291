import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface MyContextValue {
  value: string| undefined;
  updateValue: (newValue: string | undefined) => void;
  cnt: number;
  updateCnt: () => void;
}
type WithChildren = {
  children?: ReactNode
}


const GroupContext = createContext<MyContextValue | undefined>(undefined);

const GroupContextProvider: React.FC<WithChildren> = ({ children }) => {
  const [value, setValue] = useState<string | undefined>('-1');
  const [cnt, setCnt] = useState(0);
  
  const updateValue = (newValue: string | undefined) => {
    setValue(newValue);
  };
  const updateCnt = () => {
    setCnt(prevCnt => prevCnt + 1);
  };


  const contextValue: MyContextValue = {
    value,
    updateValue,
    cnt,
    updateCnt
  };

  return (
    <GroupContext.Provider value={contextValue}>
      {children}
    </GroupContext.Provider>
  );
};

const useGroupManageRequest = () => useContext(GroupContext);
export { GroupContextProvider, useGroupManageRequest };