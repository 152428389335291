import { Link } from 'react-router-dom';
import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'
import { GroupuseListView } from '../../../../friend-group-manage/GroupListViewProvider'

const SendGrouping = () => {
  const {selected, clearSelected} = GroupuseListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()


  const ShowSensBox = () => {


    return (
      <Link to={`/sendmessage2`} state={{ selected : selected }}>
        <button type='button' className='btn btn-primary'>
          <i className="fas fa-envelope-open-text fs-4 me-2"></i> 
          전송 메시지 입력
        </button>
         </Link>
    );
  };



  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> 명
      </div>

      {ShowSensBox()}
    </div>
  )
}

export {SendGrouping}
