import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../_metronic/layout/core'
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../_metronic/partials/widgets'

import { GetProgramDownload, getUsersGroup } from '../modules/apps/friend-add/friend-list/core/_requests'
import { ProgramInfo } from '../modules/apps/friend-add/friend-list/core/_models'
import { SignInMethod } from './myprofile/SignInMethod'
import { ProfileDetails } from './myprofile/ProfileDetails'
import { ConnectedAccounts } from '../modules/accounts/components/settings/cards/ConnectedAccounts'



const MyProfilePage: FC = () => {
  const [programDownloadLink, setProgramDownloadLink] = useState<string | undefined>('');

  async function fetchData() {
    GetProgramDownload().then((result: ProgramInfo) => {
      setProgramDownloadLink(result.path);
    });
  }


  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>


      <ProfileDetails />
      {/* <SignInMethod /> */}

    </>
  )
}


const MyProfile: FC = () => {


  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'피치톡'}</PageTitle>
      <MyProfilePage />
    </>
  )
}

export { MyProfile }
