/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'


import {getUsersGroup}  from '../friend-add/friend-list/core/_requests'
import {User} from '../friend-add/friend-list/core/_models'
import { GroupuseQueryRequest } from './GroupQueryRequestProvider'
import { useGroupRequest } from './GroupContextProvider'


const GroupQueryResponseContext = createResponseContext<User>(initialQueryResponse)
const GroupQueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = GroupuseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [idx, setIdx] = useState<string | undefined >('-1');

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])


  const value = useGroupRequest();
  useEffect(() => {
    setIdx(value?.value);
  }, [value?.value])  

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    '',
    () => {
      return getUsersGroup(value?.value)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
 

  useEffect(() => {
    refetch()
  }, [value?.value,value?.cnt])



  return (
    <GroupQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </GroupQueryResponseContext.Provider>
  )
}

const GroupuseQueryResponse = () => useContext(GroupQueryResponseContext)

const GroupuseQueryResponseData = () => {
  const {response} = GroupuseQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const GroupuseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = GroupuseQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const GroupuseQueryResponseLoading = (): boolean => {
  const {isLoading} = GroupuseQueryResponse()
  return isLoading
}

export {
  GroupQueryResponseProvider,
  GroupuseQueryResponse,
  GroupuseQueryResponseData,
  GroupuseQueryResponsePagination,
  GroupuseQueryResponseLoading,
}
