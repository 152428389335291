import React, { FC, useState } from 'react';
import { useWriteMessage } from './WriteMessageProvider';
import clsx from 'clsx';

const WriteMessage: FC = () => {
  const maxLength = 2000;
  
  const [message, setMessage] = useState<string>('');  // 메시지 내용
  const { text, setText } = useWriteMessage(); // 글자 수
  
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]); // 파일 첨부  
  const {imageFiles, setImageFiles} = useWriteMessage() // 이미지 입력


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // 선택한 파일들의 이름을 배열로 추출하여 저장
      const fileNames: string[] = Array.from(files).map((file) => file.name);
      setSelectedFiles(fileNames);

      // 파일 정보 저장
      const fileArray = Array.from(files);
      setImageFiles(fileArray);
    }
  };

  // textarea의 내용이 변경될 때 호출되는 이벤트 핸들러
  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;

    // 상태 변수 업데이트
    setMessage(text);
    setText(text);
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>메시지 입력</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top'>
            <div className='row mb-0'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 '>메시지</label>
              <textarea
              className='form-control bg-transparent'
                placeholder="메시지 내용을 입력해 주세요"
                maxLength={maxLength}
                value={message}
                rows={10}
                onChange={handleTextareaChange} // textarea 값 변경 이벤트 핸들러
              />
              <div>
                <p>{message.length}/{maxLength}자</p>
              </div>
            </div>
          </div>
        </div>


        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top'>
            <div className='row mb-0'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>파일 첨부</label>
            </div>

            <div className='row mb-0'>
              <div className='col-lg-12'>
                {/* 파일 선택을 위한 input 요소 */}
                <input
                  type="file"
                  accept="image/*"
                  id="ex_filename"
                  className={clsx(
                    'form-control bg-transparent'
                  )}
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </div>

          </div>



          {/* <div>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file}</li>
              ))}
            </ul>
          </div> */}

        </div>

      </div>
    </>
  );
}

export { WriteMessage };
