import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../_metronic/layout/core'
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../_metronic/partials/widgets'
import { UsersListWrapper } from '../modules/apps/friend-add/friend-list/FriendList'
import { FriendAddListWrapper } from '../modules/apps/friend-group-manage/FriendaAddList'
import { GroupContextProvider } from '../modules/apps/friend-group-manage/GroupContextProvider'
import { SendFriendGoupListWrapper } from '../modules/apps/friend-group-manage/SendFriendGroupList'
import { Send_GetProgramStatus } from '../modules/apps/friend-add/friend-list/core/_requests'


const SendMessagePage1: FC = () => (
  <>

    <div className='col gy-5 gx-xl-5'>
      <div className='col-xxl-12'>
        <GroupContextProvider>
          <SendFriendGoupListWrapper />
        </GroupContextProvider>
      </div>


      {/* <div className='col-xxl-6 mt-2'>
        <FriendGoupListWrapper />
      </div> */}
    </div>



  </>
)


const SendMessage1: FC = () => {
    
  useEffect(() => {
    Send_GetProgramStatus();
  }, []);

  
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'발송 인원 선택'}</PageTitle>
      <SendMessagePage1 />
    </>
  )
}

export { SendMessage1 }
