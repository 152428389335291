/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {checkPhoneNum as CheckUseable, getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import { link } from 'fs'


const initialValues = {
  phonenum:'',
  username: '',
  email: '',
  password: '',
  changepassword: '',
}
const registrationSchema = Yup.object().shape({

  phonenum: Yup.string()  
  .min(9, '최소 9글자 이상 입력해주세요')
  .max(11, '최대 11글자 이하로 입력해주세요.')
    .test('checkPhoneNumber', '이미 등록 되어있는 번호입니다.', async function (value) {
      
      if (value) {       

        console.log("state : " + this.parent.state );
        console.log("initialValues : " + initialValues.phonenum);

        if(initialValues.phonenum != value)
        {
          const { data: YesNoModel } = await CheckUseable(value);
          initialValues.phonenum = value;
          return YesNoModel.result !== false;
        }
        else
        {
          return true;
        }
      }

      return true;

    })
    .required('휴대폰 번호는 필수 입력입니다.'),

  username: Yup.string()
    .min(2, '최소 2글자 이상 입력해주세요.')
    .max(10, '최대 10글자 이하로 입력해주세요.')
    .required('이름은 필수 입력입니다.'),
  email: Yup.string()
    .email('이메일 형식에 맞지 않습니다.')
    .min(5, '최소 5글자 이상 입력해주세요')
    .max(50, '최대 50글자 이하로 입력해주세요')
    .required('이메일은 필수 입력입니다.'),
  password: Yup.string()
    .min(3, '최소 3글자 이상 입력해주세요')
    .max(50, '최대 50글자 이하로 입력해주세요')
    .required('비밀번호는 필수 입력입니다.'),
  changepassword: Yup.string()
  .min(3, '최소 3글자 이상 입력해주세요')
  .max(50, '최대 50글자 이하로 입력해주세요')
    .required('비밀번호 확인은 필수 입력입니다.')
    .oneOf([Yup.ref('password')], "비밀번호가 일치하지 않습니다."),
})


export function Registration() {
  const [loading, setLoading] = useState(false)
  const {setCurrentUser} = useAuth()
  const {saveAuth} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.phonenum,          
          values.username,
          values.email,          
          values.password
        )

        if (false) {
          saveAuth(auth)
          const { data: user } = await getUserByToken(auth.api_token)
          setCurrentUser(user)
        }
        else
        {
          setStatus('가입이 완료되었습니다.')
          setSubmitting(false)
          setLoading(false)
        }



      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('가입 실패')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])


  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>회원가입</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>환영합니다. 가입하고 지금바로 시작해보세요.</div>
      </div>
      {/* end::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9' style={{display:'none'}}>
        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}


      {/* begin::Form group Firstname */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>휴대폰번호</label>
        <input
          placeholder='01012341234'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phonenum')}
          onBlur={formik.handleBlur}
          value={formik.values.phonenum}
          className={clsx(
            'form-control bg-white',
            {
              'is-invalid': formik.touched.phonenum && formik.errors.phonenum,
            },
            {
              'is-valid': formik.touched.phonenum && !formik.errors.phonenum,
            }
          )}
        />
        {formik.touched.phonenum && formik.errors.phonenum && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phonenum}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>이름</label>
        <input
          placeholder='이름을 입력해주세요'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-white',
            {
              'is-invalid': formik.touched.username && formik.errors.username,
            },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>이메일 주소</label>
        <input
          placeholder='이메일 주소'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-white',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-5' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>비밀번호</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='비밀번호'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-white',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          문자, 숫자, 특수문자를 포함하여 8자리 이상 입력을 권장합니다.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>비밀번호 확인</label>
        <input
          type='password'
          placeholder='비밀번호 확인'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-white',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>가입</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              가입 중...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            취소
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
