import {FC, useState, createContext, useContext, useMemo} from 'react'
import { ID, ListViewContextProps, initialListView } from '../../../../_metronic/helpers/crud-helper/models'
import { WithChildren, calculateIsAllDataSelected, calculatedGroupingIsDisabled, groupingOnSelect, groupingOnSelectAll } from '../../../../_metronic/helpers'
import { GroupuseQueryResponse, GroupuseQueryResponseData } from './GroupQueryResponseProvider'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const GroupListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const {isLoading} = GroupuseQueryResponse()
  const data = GroupuseQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const GroupuseListView = () => useContext(ListViewContext)

export {GroupListViewProvider, GroupuseListView}
