import React, { FC, createContext, useContext, useState } from 'react';
import { WriteMessageContextProps, initWriteMessage } from '../../../../_metronic/helpers/crud-helper/models';
import { WithChildren } from '../../../../_metronic/helpers';

const WriteMessageContext = createContext<WriteMessageContextProps>(initWriteMessage)

const WriteMessageProvider: FC<WithChildren> = ({ children }) => {
  const [selected, setSelected] = useState<Array<String>>([]); // selected 상태를 초기화
  const [text, setTextValue] = useState<String>('메시지 내용을 입력해 주세요'); // selected 상태를 초기화
  const [imageFiles, setImageFilesInfo] = useState<File[]>([]); // 이미지 입력  
  const [sent_current, SetSentCnt] = useState<number>(0);

  // selected 값을 설정하는 함수 추가
  const setSelectedItems = (id : Array<String>) => {
    setSelected(id);
  };

  const setText = (text : String) => {
    setTextValue(text);
  };

  const setImageFiles = (img : File[]) => {
    setImageFilesInfo(img);
  };

  const setSentCurrent = (cnt : number) => {
    SetSentCnt(cnt);
  };

  


  
  const [is_show, ShowLoading] = useState<Boolean>(false);
  const setVisible = (flag : Boolean) => {
    ShowLoading(flag);
  };


  return (
    <WriteMessageContext.Provider
     value={{ 
      selected,
      setSelectedItems,

      text,
      setText,

      imageFiles,
      setImageFiles,

      is_show,
      setVisible,

      sent_current,
      setSentCurrent,

       }}>
    {children}
    </WriteMessageContext.Provider>
  );
};

const useWriteMessage = () => useContext(WriteMessageContext)

export { WriteMessageProvider, useWriteMessage };
