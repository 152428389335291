import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../_metronic/helpers'

const QueryRequestContext_Manage = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
    <QueryRequestContext_Manage.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext_Manage.Provider>
  )
}

const useQueryRequest_Manage = () => useContext(QueryRequestContext_Manage)
export {QueryRequestProvider, useQueryRequest_Manage}
