import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

import {Home} from '../pages/Home'
import {SendMessage1} from '../pages/SendMessage1'
import {SendMessage2} from '../pages/SendMessage2'
import { GroupManage } from '../pages/GroupManage'


import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { Coupon } from '../pages/Coupon'
import { MyPass } from '../pages/MyPass'
import { MyProfile } from '../pages/MyProfile'
import { SendFriendAll_Stop, Send_SendMessageStop } from '../modules/apps/friend-add/friend-list/core/_requests'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const AddFriendPage = lazy(() => import('../pages/AddFriendPage'))  


  const location = useLocation()

  useEffect(() => {
    
    console.log('Route changed to:', location.pathname)

    // 페이지 이동 시 친구등록, 메시지전송 중지되도록 기능 추가
    SendFriendAll_Stop();
    Send_SendMessageStop();
    

  }, [location])


  return (
    <Routes>
      <Route element={<MasterLayout />}>

        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />

        <Route path='home' element={<Home />} />
        <Route path='myprofile' element={<MyProfile />} />
        {/* <Route path='addfriend' element={<AddFriendPage />} />         */}
        <Route
          path='apps/friend-add/*'
          element={
            <SuspensedView>
              <AddFriendPage />
            </SuspensedView>
          }
        />
        <Route
          path='groupmanage'
          element={
            <SuspensedView>
              <GroupManage />
            </SuspensedView>
          }
        />    

        <Route path='sendmessage1' element={<SendMessage1 />} />           
        <Route path='sendmessage2' element={<SendMessage2 />} />           
        
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='coupon' element={<Coupon />} />
        <Route path='myproduct' element={<MyPass />} />

        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
