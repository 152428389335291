import { FC, useState } from 'react'
import { useWriteMessage } from '../WriteMessageProvider'
import { Send_SendMessageStop } from '../../friend-add/friend-list/core/_requests';
import { KTIcon } from '../../../../../_metronic/helpers';

const ModalSendingForm: FC = () => {

  const { selected } = useWriteMessage();
  const { is_show, setVisible } = useWriteMessage()

  const { sent_current, setSentCurrent } = useWriteMessage();

  // const { setItemIdForUpdate } = useListView()
  // const { refetch } = useQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    setVisible(false)
    
    Send_SendMessageStop();

    //setIsUserLoading(false);
    // if (withRefresh) {
    //   refetch()
    // }
    // setItemIdForUpdate(undefined)
    // IntervalFindFriend_End();
  }


  return (
    <>
      <form id='kt_modal_add_user_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          <div className='fv-row mb-7 text-center'>
            
          <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={
          () =>{
            
          setVisible(false)
          }           
        }
        style={{ cursor: 'pointer', position: 'absolute', right: '0px' }}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>



          <label className='fw-bold fs-1 mb-2 ' style={{ color: '#f97f50' }}>메시지 전송</label>
            <br></br><br></br>

            <span className='fw-bold align-center mx-1' style={{fontSize:'50px'}}>
              {sent_current} / {selected.length} 명
            </span>
            <br></br>
            <label className='fw-bold  mb-2 ' style={{ color: '#f97f50', fontSize:'25px' }}>메시지를 전송 중 입니다..<span style={{fontSize:'25px' }} className="spinner-border spinner-border-sm align-middle ms-2"></span>     </label>
            <br></br>
            <br></br>
            {/* begin::Label */}
            <label className='fw-bold fs-5 mb-2'>마우스, 키보드를 건들이지 마세요.</label>
            <br></br>
            <label className='fw-bold fs-6 mb-2'>등록 과정에서 카카오톡 채팅창이 열리고 닫힙니다.</label>
            {/* end::Label */}


            {/* begin::Label */}

          </div>

          <span className='fs-3 align-center mx-1'>
            {/* {status_info} 명 */}
          </span>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          {sent_current != selected.length ? (
            <button type='reset' onClick={() => cancel()}
              className='btn btn-primary  me-3 p-20 pt-3 pb-3 fw-bold fs-5' data-kt-users-modal-action='cancel'>전송 중지</button>
          ) : (
            <button type='reset' onClick={() => cancel()} className='btn btn btn-primary'
              data-kt-users-modal-action='cancel'>전송 완료</button>
          )}
        </div>
        {/* end::Actions */}
      </form>
    </>
  )
}



export { ModalSendingForm }
