import {FC, PropsWithChildren, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row, HeaderProps} from 'react-table'
import clsx from 'clsx'
import {Column} from 'react-table'

// ui
import { KTCardBody, initialQueryState } from '../../../_metronic/helpers'

import { GroupuseQueryResponseData, GroupuseQueryResponseLoading } from './GroupQueryResponseProvider_GroupManage'
import { GroupuseListViewManage } from './GroupListViewProvider_Manage'
import { useQueryRequest_Manage } from './QueryRequestProvider_Manage'

const GroupEditPopup = () => {
  const isLoading = GroupuseQueryResponseLoading()

  const users = GroupuseQueryResponseData()  
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })



  

  return (
    <KTCardBody className='py-4 p-10'>
      <div className='table-responsive'  style={{height:'50vh', overflow:'auto'}}>

        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<GroupList>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<GroupList>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    등록된 친구가 없습니다.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>


      <style>
        {`
          .table-responsive::-webkit-scrollbar {
            width: 10px;
          }          
          .table-responsive::-webkit-scrollbar-thumb {
            height: 30%;
            background: #0095e8 !important;
            border-radius: 10px;
          }          
          .table-responsive::-webkit-scrollbar-track {
            background: rgba(33, 122, 244, 0.1);
          }
        `}
      </style>


      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {GroupEditPopup}




type Props3 = {
  id: ID
}

const UserSelectionCell: FC<Props3> = ({id}) => {
  const {selected, onSelect} = GroupuseListViewManage()
  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
    </div>
  )
}

export {UserSelectionCell}


type Props4 = {
  tableProps: PropsWithChildren<HeaderProps<GroupList>>
}

const handleCheckboxClick = () => {
  //alert("@@");
};


const UserSelectionHeader: FC<Props4> = ({tableProps}) => {
  const {isAllSelected, onSelectAll} = GroupuseListViewManage()
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={isAllSelected}
          data-kt-check-target='#kt_table_users .form-check-input'
          checked={isAllSelected}
          onChange={onSelectAll}
          onClick={handleCheckboxClick}
        />
      </div>
    </th>
  )
}

export {UserSelectionHeader}


type Props5 = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<GroupList>>
}
const UserCustomHeader: FC<Props5> = ({className, title, tableProps}) => {
  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest_Manage()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      // enable sort asc
      updateState({sort: id, order: 'asc', ...initialQueryState})
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        // enable sort desc
        updateState({sort: id, order: 'desc', ...initialQueryState})
        return
      }

      // disable sort
      updateState({sort: undefined, order: undefined, ...initialQueryState})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}

export {UserCustomHeader}



type Props6 = {
  last_login?: string
}

const UserLastLoginCell: FC<Props6> = ({last_login}) => (
  <div className='badge badge-light fw-bolder'>{last_login}</div>
)

export {UserLastLoginCell}



type Props7 = {
  user: GroupList
}

const UserInfoCell: FC<Props7> = ({user}) => (
  <div className='d-flex align-items-center'>

    <div className='d-flex flex-column'>
      <span className='text-gray-800 mb-1 fs-4 /*text-hover-primary*/ '>
        {user.name}
      </span>
    </div>
  </div>
)

export {UserInfoCell}


type Step = {
  id: string;
  name: string;
};

type Props8 = {
  two_steps?: boolean;
};


const UserTwoStepsCell: FC<Props8> = ({ two_steps }) => {
  let steps: Step[] = [];

  try {
    if (two_steps) {
     // steps = JSON.parse(two_steps.replace(/id/g, '"id"').replace(/name/g, '"name"')) as Step[];
    }
  } catch (error) {
    console.error('Error parsing two_steps:', error);
  }

  return (
    <>
      {steps.map((step) => (
        <div className='badge badge-light-success fw-bolder m-2 fs-5' key={step.id}>
          {step.name}
        </div>
      ))}
    </>
  );
};

export {UserTwoStepsCell}



const usersColumns: ReadonlyArray<Column<GroupList>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='그룹명' className='min-w-100px fs-4' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='전송 그룹' className='min-w-325px fs-4' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='등록일' className='min-w-125px fs-4' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='마지막 전송일' className='min-w-125px fs-4' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },  
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {usersColumns}



export type ID = undefined | null | number

export type GroupList = {
  id?: ID
  name?: string
}


type Props2 = {
  column: ColumnInstance<GroupList>
}

const CustomHeaderColumn: FC<Props2> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

export {CustomHeaderColumn}



type Props = {
  row: Row<GroupList>
}

const CustomRow: FC<Props> = ({row}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}


const UsersListLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>데이터를 불러오고 있습니다.</div>
}

export {UsersListLoading}


