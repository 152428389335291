import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {FriendList, GroupList, JsonResult, ProgramInfo, StatusInfo, User, UsersQueryResponse, UsersQueryResponse2} from './_models'
import { error } from 'console'
import { MyInfo } from '../../../../../DataManager/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query.php`
const INSERT_FRIEND = `${API_URL}/users/insert_friend.php`
const DELETE_FRIEND = `${API_URL}/users/delete_friend.php`
const DELETE_GROUP_FRIEND = `${API_URL}/users/delete_friend_group.php`

const INSERT_GROUP_FRIEND = `${API_URL}/users/insert_group_friend.php`
const INSERT_GROUP = `${API_URL}/users/insert_group.php`
const GET_GROUP = `${API_URL}/users/get_group.php`
const GET_USERS_GROUP = `${API_URL}/users/query_group.php`
const GET_USERS_GROUP_LISt = `${API_URL}/users/query_group_list.php`


const GET_USERS_GROUP_SEND = `${API_URL}/users/query_group_send.php`
const GET_PROGRAM_PATH = `${API_URL}/users/get_program_path.php`
const GET_MYINFO = `${API_URL}/get_myinfo.php`

const USE_CUPON = `${API_URL}/users/use_cupon.php`
const GET_TICKET = `${API_URL}/users/get_ticket.php`

//const APP_URL_LOCAL = "http://localhost:46301";

//axios.defaults.withCredentials = true;


const APP_URL_LOCAL = "http://localhost:48690";



const GetTicket = (code : string): Promise<JsonResult>  => 
  { 
      // POST 요청 데이터
      const postData = {
      };
  
    return axios
      .post(`${GET_TICKET}`, postData, {
        headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer ".concat("")
        }
    })
  
    .then((response: AxiosResponse<JsonResult>) => response.data)
  }


const UseCupon = (code : string): Promise<JsonResult>  => 
{ 
    // POST 요청 데이터
    const postData = {
      code : code,      
    };

  return axios
    .post(`${USE_CUPON}`, postData, {
      headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer ".concat("")
      }
  })

  .then((response: AxiosResponse<JsonResult>) => response.data)
}


const GetProgramDownload = (): Promise<ProgramInfo>  => 
{
  return axios
    .post(`${GET_PROGRAM_PATH}`, {
      headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer ".concat("")
      }
  })

  .then((response: AxiosResponse<ProgramInfo>) => response.data)
}


const GetMyInfo = (): Promise<MyInfo>  => 
  {
    return axios
      .post(`${GET_MYINFO}`, {
        headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer ".concat("")
        }
    })
  
    .then((response: AxiosResponse<MyInfo>) => response.data)
  }

  

const Send_GetProgramStatus = () => {
  return axios
    .get(`${APP_URL_LOCAL}/status`, {
      //withCredentials: true, // 쿠키 cors 통신 설정
      headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer ".concat("")
      }
  })
  .then(() => {
    
    })
    .catch((error) => {
      alert("피치톡을 실행해주세요");
      // if (error.response && error.response.status === 404) {
      //   // 404 에러를 무시하고 처리할 코드 작성
      // } else {
      //   // 다른 에러는 다시 throw하여 처리되도록 함
      //   throw error;
      // }
    });
}


const Send_SendMessage  = (name : String, send_msg : String, files : any) => {

  // messages 생성
  const list_message = [];
  const array_name = name.split(',');
  const n_message = array_name.length;

  for (let i = 0; i < n_message; i++) {
    const message = {
      name: array_name[i].trim(),
      sent_message: send_msg,
      sender_type: 'Honorific',
    };
    list_message.push(message);
  }

    // POST 요청 데이터
    const postData = {
      messages : list_message,
      attatched_files : files,
      friend_group_id: '47405',
      first_image : 'True',  /* True : 이미지 먼저 전송, False : 이미지 나중에 전송 */
      image_bundle : "False", /* True : 이미지 묶어서 전송, False : 이미지 개별 전송 */
    };


  // 서버 전송
  axios.post(`${API_URL}/users/send.php`, postData, {
    headers: {
      Accept: "application/json",
      ContentType: "application/json",
      Authorization: "Bearer ".concat("")
    }
  });


  return axios
    .post(`${APP_URL_LOCAL}/send`, postData, {
      headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer ".concat("")
      }
  })
    .then(() => {})
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        // 404 에러를 무시하고 처리할 코드 작성
      } else {
        // 다른 에러는 다시 throw하여 처리되도록 함
        throw error;
      }
    });
}
const Send_SendMessageStop  = () => {

  return axios
    .post(`${APP_URL_LOCAL}/send/stop`, {
      headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Bearer ".concat("")
      }
  })
    .then(() => {})
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        // 404 에러를 무시하고 처리할 코드 작성
      } else {
        // 다른 에러는 다시 throw하여 처리되도록 함
        throw error;
      }
    });
}


const SendFriendAll_Start  = () => {
  return axios.post(`${APP_URL_LOCAL}/friends/synchronize`, {mode:'Start-all'}, {
    headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer ".concat("")
    }
})
  .then(() => { })
  .catch((error) => {
    if (error.response && error.response.status === 404) {
      // 404 에러를 무시하고 처리할 코드 작성
    } else {
      //throw error; // 다른 에러는 다시 throw하여 처리되도록 함
    }
  });
}

const SendFriendAll_Stop = (): Promise<boolean> => {
  return axios.post(`${APP_URL_LOCAL}/friends/synchronize`, {mode:'Stop'}, {
    headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer ".concat("")
    }
})
.then(() => 
  {
    return true;
   })
  .catch((error) => {
    if (error.response && error.response.status === 404) {
      // 404 에러를 무시하고 처리할 코드 작성
    } else {
      //throw error; // 다른 에러는 다시 throw하여 처리되도록 함
    }
    return false;    
  });
}


const Send_Status  = () : Promise<StatusInfo> => {
  return axios.get(`${APP_URL_LOCAL}/status?telephone=`, {
    headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer ".concat("")
    }
})

  .then((response: AxiosResponse<StatusInfo>) => response.data)
}


const Send_Friends  = (token: string | undefined) : Promise<FriendList> => {
  return axios.get(`${APP_URL_LOCAL}/friends`, {
    headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer ".concat("")
    }
})

    .then(async (response: AxiosResponse<FriendList>) => {

      // 친구 추가
      await  axios.post(`${INSERT_FRIEND}`, {data:response.data, 
        api_token: token});

      return response.data
    });
}

const Add_Group_Friend  = (friend_id : ID[], group_id : string|undefined) : Promise<GroupList> => {
  return axios.post(`${INSERT_GROUP_FRIEND}`, {data:friend_id, group_id})
    .then((response: AxiosResponse<GroupList>) => {

      return response.data
    });
}


const GetGroup = (): Promise<GroupList[]> => {
  return axios
    .get<GroupList[]>(`${GET_GROUP}`)
    .then((d: AxiosResponse<GroupList[]>) => d.data)
}

const getUsersGroup = (query: string | undefined): Promise<UsersQueryResponse2> => {
  return axios
    .get(`${GET_USERS_GROUP}?group=${query}`)
    .then((d: AxiosResponse<UsersQueryResponse2>) => d.data)
}


const getUsersGroupList = (query: string | undefined): Promise<UsersQueryResponse2> => {
  return axios
    .get(`${GET_USERS_GROUP_LISt}?group=${query}`)
    .then((d: AxiosResponse<UsersQueryResponse2>) => d.data)
}


// Server should return AuthModel
export function register_group(
  // 호출하는 곳의 함수 순서를 맞춰야 함
  group_name: string,
) {
  return axios.post(INSERT_GROUP, {
    group_name
  })
}



const getUsersGroupSend = (query: string | undefined): Promise<UsersQueryResponse2> => {
  return axios
    .get(`${GET_USERS_GROUP_SEND}?group=${query}`)
    .then((d: AxiosResponse<UsersQueryResponse2>) => d.data)
}


const deleteSelectedFriend = (friend_sq: Array<ID>, type : String): Promise<void> => {
  return axios.post(`${DELETE_FRIEND}`, {friend_sq, type})
    .then(() => {})

  // const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))

  // return axios.all(requests).then(() => {})
}


const deleteSelectedFriendGroup = (friend_sq: Array<ID>, type : String): Promise<void> => {
  return axios.post(`${DELETE_GROUP_FRIEND}`, {friend_sq, type})
    .then(() => {})

  // const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))

  // return axios.all(requests).then(() => {})
}


// 기존
const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser,  getUsersGroupList,
  Send_SendMessage, deleteSelectedFriend, Send_GetProgramStatus,
  SendFriendAll_Start, SendFriendAll_Stop, Send_Status, Send_Friends, Send_SendMessageStop,
  GetGroup, getUsersGroupSend, deleteSelectedFriendGroup, GetMyInfo,
  Add_Group_Friend, getUsersGroup, GetProgramDownload, UseCupon}
