import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { GetMyInfo } from '../../modules/apps/friend-add/friend-list/core/_requests'
import { MyInfo } from '../../DataManager/_models'
import { ModalLoading } from '../../modules/control/Loading'

const profileDetailsSchema = Yup.object().shape({
  // fName: Yup.string().required('First name is required'),
  // lName: Yup.string().required('Last name is required'),
  // company: Yup.string().required('Company name is required'),
  // contactPhone: Yup.string().required('Contact phone is required'),
  // companySite: Yup.string().required('Company site is required'),
  // country: Yup.string().required('Country is required'),
  // language: Yup.string().required('Language is required'),
  // timeZone: Yup.string().required('Time zone is required'),
  // currency: Yup.string().required('Currency is required'),
})

const ProfileDetails: React.FC = () => {

  // 자식 Dialog Loading 용 함수들
  const [is_show_loading, setVisibleLoading] = useState<boolean>(false); // is_show 상태 추가  
  const handleSetLoading = (isVisible: boolean) => {
    setVisibleLoading(isVisible); // 부모 컴포넌트의 상태 변경
  };


  const [name, setName] = useState<string | undefined>(); // 상태 선언 및 초기값 설정
    const [myinfo, setMyInfo] = useState<MyInfo | undefined>();

  async function fetchData() {
    
    setVisibleLoading(true);

      GetMyInfo().then((result: MyInfo) => {
      setMyInfo(result);

      setName(result.user_nm);
      setVisibleLoading(false);
    });
  }


  useEffect(() => {
    //setLoading(true)

    fetchData();
    
    //setLoading(false)
  }, []);


  


  // 업데이트 시 예시
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    //setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        values.communications.email = data.communications.email
        values.communications.phone = data.communications.phone
        values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setLoading(false)
      }, 1000)
    },
  })



  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>내정보</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>


          <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>가입일</label>

              <div className='col-lg-8 fv-row'>
                <span className='form-control-lg'  style={{border:'1px solid #f3f3f3'}}>{myinfo?.join_dt}</span>
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>아이디</label>

              <div className='col-lg-8 fv-row'>
                <span className='form-control-lg' style={{border:'1px solid #f3f3f3'}}>{myinfo?.id_nm}</span>
                    {/* {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        로딩중{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )} */}
              </div>
            </div>



            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>이름</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='이름'
                      value={myinfo?.user_nm} // 변수를 값으로 설정
                    />                    
                  </div>
                  </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>이메일</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  value={myinfo?.email_nm} // 변수를 값으로 설정
                />
              </div>
            </div>


{/* 
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>비밀번호</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='비밀번호'
                />
              </div>
            </div> */}


{/* 
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>답변회신</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      className='form-check-input'
                      name='communication[]'
                      type='checkbox'
                      defaultChecked={data.communications?.email}
                      onChange={() => {
                        updateData({
                          communications: {
                            email: !data.communications?.email,
                            phone: data.communications?.phone,
                          },
                        })
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>이메일</span>
                  </label>

                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      className='form-check-input'
                      name='communication[]'
                      type='checkbox'
                      defaultChecked={data.communications?.phone}
                      onChange={() => {
                        updateData({
                          communications: {
                            email: data.communications?.email,
                            phone: !data.communications?.phone,
                          },
                        })
                      }}
                    />
                    <span className='fw-bold ps-2 fs-6'>핸드폰</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-0'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>업데이트 및 광고 수신</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    id='allowmarketing'
                    defaultChecked={data.allowMarketing}
                    onChange={() => {
                      updateData({allowMarketing: !data.allowMarketing})
                    }}
                  />
                  <label className='form-check-label'></label>
                </div>
              </div>
            </div> */}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && '수정'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  잠시만 기다려주세요...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      <ModalLoading is_show={is_show_loading} setVisible={handleSetLoading} /> {/* ModalMessage 컴포넌트에 is_show와 setIsShow 전달 */}
    </div>
  )
}

export {ProfileDetails}
