import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../_metronic/layout/core'
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../_metronic/partials/widgets'

import { GetProgramDownload, getUsersGroup } from '../modules/apps/friend-add/friend-list/core/_requests'
import { ProgramInfo } from '../modules/apps/friend-add/friend-list/core/_models'



const HomePage: FC = () => {
  const [programDownloadLink, setProgramDownloadLink] = useState<string | undefined>('');

  async function fetchData() {
    GetProgramDownload().then((result: ProgramInfo) => {
      setProgramDownloadLink(result.path);
    });
  }


  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>

      <div className='row'>

        <div className="col-xl-4">
          <a href="javascript:alert('메뉴얼 준비중')" className="btn px-6 m-2 p-20" style={{ backgroundColor: '#ffe6d0' }}>
            <img src='/media/peachtalk/howto.png' className="col-xl-6" />
            <span className="d-flex flex-column align-items-center ms-2 ">
              <span className="fs-1 bold" style={{ color: '#000' }}>피치톡 사용법 알아보기</span>
            </span>
          </a>

        </div>
        <div className="col-xl-4">
          <a href={programDownloadLink} target='_blank' className="btn px-6  p-20" style={{ backgroundColor: '#ffe6d0' }}>
            <img src='/media/peachtalk/download.png' className="col-xl-6" />
            <span className="d-flex flex-column align-items-center ms-2 ">
              <span className="fs-1 bold" style={{ color: '#000' }}>피치톡 다운로드</span>
            </span>
          </a>
        </div>


        <div className='col-xl-3'>
          <div className="row">

            <div className='card mb-10'>
              <div className='card-body d-flex align-items-center py-3 ' style={{ background: '#fff9f6' }}>

                <div className='ms-6 ,-2'>

                <p className='list-unstyled text-gray-800 fw-bold fs-4 p-0 m-2 mt-10'>
                    운용환경
                  </p>

                  <div className='m-14'>
                    <span className="fs-1"><i className="bi bi-windows fs-2 m-2"></i> Window</span>
                  </div>


                </div>
              </div>
            </div>

          </div>
          <div className="row">


            <div className='card mb-10'>
              <div className='card-body d-flex align-items-center py-3 ' style={{ background: '#ffeded' }}>

                <div className='ms-6 ,-2'>

                <p className='list-unstyled text-gray-800 fw-bold fs-4 p-0 m-2  mt-5'>
                    주의사항
                  </p>
                  <div className='m-4 mt-10'>
                    <span className="fs-5">무분별한 메시지 발송은<br></br> 아이디 정지의 위험이 됩니다.</span>
                  </div>


                </div>
              </div>
            </div>




          </div>


        </div>
      </div>



    </>
  )
}



const Home: FC = () => {


  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'피치톡'}</PageTitle>
      <HomePage />
    </>
  )
}

export { Home }
