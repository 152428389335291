import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../_metronic/layout/core'
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../_metronic/partials/widgets'
import { JsonResult } from '../modules/apps/friend-add/friend-list/core/_models'
import { UseCupon } from '../modules/apps/friend-add/friend-list/core/_requests'
import { ModalMessage } from '../modules/control/ModalMessage'
import { ModalLoading } from '../modules/control/Loading'


const CouponPage: FC = () => {

  const [couponCode, setCouponCode] = useState<string>("");

  function fetchData() {
    setVisibleLoading(true);
    UseCupon(couponCode).then((result: JsonResult) => {

      setMessage(result.result_msg);
      handleSetMessageVisible(true);

      setVisibleLoading(false);
    });
  }

  // 자식 Dialog Loading 용 함수들
  const [is_show_loading, setVisibleLoading] = useState<boolean>(false); // is_show 상태 추가  
  const handleSetLoading = (isVisible: boolean) => {
    setVisibleLoading(isVisible); // 부모 컴포넌트의 상태 변경
  };

  // 자식 Dialog MessageBox 용 함수들
  const [is_show_message, setVisibleMessage] = useState<boolean>(false); // is_show 상태 추가    
  const handleSetMessageVisible = (isVisible: boolean) => {
    setVisibleMessage(isVisible); // 부모 컴포넌트의 상태 변경
  };

  const [msg1, setMessage] = useState<string | undefined>(""); // is_show 상태 추가    



  return (
    <>
      <div className='row'>
        <div className='col-xxl-12'>
          <div className='card mb-10'>

            <div className='card-body d-flex align-items-center py-8'>

              <div className="col-8">
                <div className="row text-center card-body">

                  <input
                    placeholder='- 를 제외한 12자리 쿠폰번호 입력'
                    onChange={(e) => setCouponCode(e.target.value)}
                    type='text'
                    autoComplete='off'                    
                    maxLength={12}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); // 기본 Enter 키 동작 방지
                        fetchData();
                      }
                    }}
                    className='form-control w-50 bg-transparent' style={{ display: 'inline-block', width: 'auto', marginRight:'1em', textAlign:'center', color:'#000' }} />
                  <button
                    type='button'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary' style={{ display: 'inline-block', width: 'auto' }}>
                    <span onClick={fetchData} className='indicator-label'>쿠폰등록</span>
                  </button>
                </div>


              </div>


              <div className="col-2 text-center">

              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='col-xl-12'>
        <div className={`card ${1}`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>쿠폰 이용안내</span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3 pb-5'>
            {/* begin::Table container */}
            <span className='d-flex justify-content-start flex-column'>1. 쿠폰을 등록하면 무료 이용 기간이 끝난 후 자동으로 정기 결제가 진행됩니다.</span>
            <span className='d-flex justify-content-start flex-column'>2. 무료 이용 기간이 끝나기 전에 언제든 결제를 해제할 수 있습니다.</span>
            <span className='d-flex justify-content-start flex-column'>3. 결제 중 해제 시 남은기간 기준으로 환불됩니다. </span>            
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </div>


      {/* <div className='row gy-5 gx-xl-8'>
          <div className='col-xxl-4'>
            <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
          </div>
          <div className='col-xl-8'>
            <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
          </div>
        </div> */}



      {/*
    
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <CardsWidget20
              className='h-md-50 mb-5 mb-xl-10'
              description='Active Projects'
              color='#F1416C'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            />
            <CardsWidget7
              className='h-md-50 mb-5 mb-xl-10'
              description='Professionals'
              icon={false}
              stats={357}
              labelColor='dark'
              textColor='gray-300'
            />
          </div>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
            <ListsWidget26 className='h-lg-50' />
          </div>
          
          <div className='col-xxl-6'>
            <EngageWidget10 className='h-md-100' />
          </div>
        </div>
        <div className='row gx-5 gx-xl-10'>
          <div className='col-xxl-6 mb-5 mb-xl-10'>
          </div>
          <div className='col-xxl-6 mb-5 mb-xl-10'>
          </div>
        </div>
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xxl-4'>
            <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
          </div>
          <div className='col-xl-8'>
            <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
          </div>
        </div>
        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-4'>
            <ListsWidget2 className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-4'>
            <ListsWidget6 className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-4'>
            <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
            </div>
        </div>
        
        <div className='row g-5 gx-xxl-8'>
          <div className='col-xxl-4'>
            <MixedWidget8
              className='card-xxl-stretch mb-xl-3'
              chartColor='success'
              chartHeight='150px'
            />
          </div>
          <div className='col-xxl-8'>
            <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
          </div>
        </div> */}

      <ModalLoading is_show={is_show_loading} setVisible={handleSetLoading} /> {/* ModalMessage 컴포넌트에 is_show와 setIsShow 전달 */}
      <ModalMessage is_show={is_show_message} msg={msg1} setVisible={handleSetMessageVisible} /> {/* ModalMessage 컴포넌트에 is_show와 setIsShow 전달 */}
    </>
  )
}

const Coupon: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'쿠폰등록'}</PageTitle>
      <CouponPage />
    </>
  )
}

export { Coupon }
